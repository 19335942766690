import React, { useEffect, useState } from "react";
import logoimages from "../../images/logo_1.png";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import Logo1 from "../../images/Screenshot_1.png";
import moment from "moment";
import {
  changeTournamentStatus,
  externalCall,
  fetchTournamentList,
  userLogout,
} from "../../api";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../app/features/userSlice";
import { showToast } from "../toast";
import Select from "react-select";

function CreateTournament() {
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [tournamentsCopy, setTournamentsCopy] = useState([]);
  const statusOptions = [
    { value: "Completed", label: "Completed" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Upcoming", label: "Upcoming" },
  ];
  const [status, setStatus] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  //fetch tournament list
  async function tournamentList() {
    const response = await fetchTournamentList();
    if (response?.data?.tournaments !== null) {
      setTournaments(response.data.tournaments);
      setTournamentsCopy(response.data.tournaments);
    } else {
      setTournaments([]);
      setTournamentsCopy([]);
    }
  }
  useEffect(() => {
    tournamentList();
  }, []);

  const handleLogout = async () => {
    const response = await userLogout();
    if (response.status === 200) {
      dispatch(logoutUser());
      showToast("Successfully logged out", 200);
      history.push({
        pathname: "/login",
      });
    }
  };

  const filterTournament = (status) => {
    if (status.trim().length !== 0) {
      setStatus(status);

      const filteredTournaments = tournamentsCopy.filter(
        (tournament) => tournament.status === status
      );

      setTournaments(filteredTournaments);
    } else {
      setTournaments(tournamentsCopy);
    }
  };

  const clearFilters = () => {
    setTournaments(tournamentsCopy);
    setStatus({});
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Upcoming":
        return "#007bff";
      case "Ongoing":
        return "#FF0000";
      case "Completed":
        return "#008000";
      default:
        return "#000";
    }
  };

  const changeTourStatus = async (status, tourId) => {
    try {
      setLoading(true);

      const response = await changeTournamentStatus({ status, tourId });

      if (response.status === 200) {
        showToast(response.data.message, 200);

        await tournamentList();

        setTimeout(async () => {
          await externalCall();

          await externalCall();
        }, 500);
      } else {
        showToast("Something went wrong", 500);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="sectionlanding">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
            <img
              src={logoimages}
              alt="logo"
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <div className="contenorlanding">
            <div className="contentrow col-12">
              <div className="nevcolumn col-2">
                <div className="nevlogo">
                  <img className="photoimagelanding" src={Logo1} alt="logo" />
                </div>
                <div className="nevlistst">
                  <li className="listnevbars">
                    <Link
                      to="/players-list"
                      className="text-decoration-none text-black"
                    >
                      Player List
                    </Link>
                  </li>

                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          style={{ fontSize: "16px", pointerEvents: "none" }}
                          className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Tournament
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion show collapse px-2"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/tournament-list"
                            className="text-decoration-none text-black px-2"
                          >
                            <span style={{ fontSize: "14px" }}>List</span>
                          </Link>
                        </div>
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/create-tournament"
                            className="text-decoration-none text-black text-sm px-2"
                          >
                            <span style={{ fontSize: "14px" }}>Create</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <li className="listnevbars">
                    <Link
                      to="/user-stats"
                      className="text-decoration-none text-black"
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </div>
              <div
                className="col-10 screensection"
                style={{ height: "auto", background: "#F8F8F8" }}
              >
                <div className="landingRow col-12 mb-4">
                  <div className="nevbarbutton p-0">
                    <button
                      className="nevbarlogbutton m-0"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center">
                  <div className="col-4"></div>
                  <h4 className="col-4 contenthadding haddingadmintext">
                    Tournament List
                  </h4>
                  <div className="col-4 adminbuttons">
                    <Select
                      value={
                        statusOptions.find((o) => o.value === status) || null
                      }
                      placeholder="Status"
                      options={statusOptions}
                      onChange={(e) => filterTournament(e.value)}
                    ></Select>
                    {Object.keys(status).length !== 0 && (
                      <button
                        disabled={Object.keys(status).length === 0}
                        className="btn btn-danger mt-1"
                        onClick={() => clearFilters()}
                      >
                        Clear Filter
                      </button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    overflowX: "auto",
                    display: "block",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <table
                    className="table table-responsive"
                    style={{ margin: "auto" }}
                  >
                    <thead className="text-center">
                      <tr className="text-nowrap text-start">
                        <th scope="">Tournament Status</th>
                        <th scope="">Tournament Name</th>
                        <th scope="">Round 1</th>
                        <th scope="">Round 1 Startdate</th>
                        <th scope="">Round 1 Enddate</th>
                        <th scope="">Round 2</th>
                        <th scope="">Round 2 Startdate</th>
                        <th scope="">Round 2 Enddate</th>
                        <th colSpan={2} className="text-center">
                          Actions
                        </th>
                        <th scope=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div className="fs-2 m-auto text-danger">
                          Loading...
                        </div>
                      ) : tournaments.length > 0 ? (
                        tournaments.map((tour) => (
                          <tr key={tour.id} style={{ verticalAlign: "middle" }}>
                            <td
                              className="text-center"
                              style={{
                                color: getStatusColor(tour.status), // Apply color based on status
                                fontWeight: "bold",
                              }}
                            >
                              {tour.status}
                            </td>
                            <td className="text-center">
                              {tour.beutifiedname
                                ? tour.beutifiedname
                                : tour.tour_name}
                            </td>
                            <td>{tour.round1.split("_").join(" ")}</td>
                            <td>
                              {moment(tour.round1_start_date)
                                .tz("America/New_York")
                                .format("MM/DD/yyyy h:mm A")}
                            </td>
                            <td>
                              {moment(tour.round1_end_date)
                                .tz("America/New_York")
                                .format("MM/DD/yyyy h:mm A")}
                            </td>
                            <td>
                              {tour.round2
                                ? tour.round2.split("_").join(" ")
                                : "-"}
                            </td>
                            <td>
                              {(tour.round2 !== "" &&
                                moment(tour.round2_start_date)
                                  .tz("America/New_York")
                                  .format("MM/DD/yyyy h:mm A")) ||
                                "-"}
                            </td>
                            <td>
                              {(tour.round2 !== "" && moment(tour.round2_end_date)
                                .tz("America/New_York")
                                .format("MM/DD/yyyy h:mm A")) || "-"}
                            </td>
                            <td>
                              <div className="d-flex flex-row gap-2">
                                <div>
                                  {tour.status !== "Completed" &&
                                    tour.status !== "Ongoing" &&
                                    Number(tour.tournament_status) === 1 && (
                                      <button
                                        onClick={() =>
                                          changeTourStatus(2, tour.id)
                                        }
                                        className="btn btn-danger"
                                      >
                                        Deactivate
                                      </button>
                                    )}
                                  {Number(tour.tournament_status) === 2 && (
                                    <button
                                      onClick={() =>
                                        changeTourStatus(1, tour.id)
                                      }
                                      className="btn btn-primary"
                                    >
                                      Activate
                                    </button>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <button
                                  onClick={() => changeTourStatus(3, tour.id)}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          style={{
                            position: "relative",
                            top: "97%",
                            alignItems: "center",
                          }}
                        >
                          <td colSpan="15">
                            <h3 className="text-center">No Data Found</h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateTournament;
